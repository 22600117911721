<template>
    <div class="list__item">
        <div class="list__item__details is-icons">
            <slot name="icons"></slot>
        </div>
        <div
            class="list__item__details is-date"
            :class="{'plus-nights': !hasNights, 'plus-name': !hasName}"
            v-if="hasDate || (!hasNights && !hasName)"
        >
            <slot name="date"></slot>
        </div>
        <div
            class="list__item__details is-date"
            v-if="hasStatus">
            <slot name="status"></slot>
        </div>
        <div class="list__item__details is-small-date" v-if="hasSmallDate">
            <slot name="small-date"></slot>
        </div>
        <div class="list__item__details is-nights" v-if="hasNights">
            <slot name="nights"></slot>
        </div>
        <div
            class="list__item__details is-name"
            v-if="hasName"
        >
            <slot name="name"></slot>
        </div>
        <div class="list__item__details is-double" v-if="hasSingle || hasDouble">
            <slot name="double"></slot>
        </div>
        <div class="list__item__details is-single" v-if="hasSingle || hasDouble">
            <slot name="single"></slot>
        </div>
        <div class="list__item__details is-profit" v-if="hasProfit">
            <slot name="profit"></slot>
        </div>
        <div class="list__item__details is-margin" v-if="hasMargin">
            <slot name="margin"></slot>
        </div>
        <div class="list__item__details is-profit-margin" v-if="hasProfitMargin">
            <slot name="profitMargin"></slot>
        </div>
        <div class="list__item__details is-buttons">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        hasDate: function () {
            return !!this.$slots.date;
        },

        hasSmallDate: function () {
            return !!this.$slots['small-date'];
        },

        hasNights: function () {
            return !!this.$slots.nights;
        },

        hasName: function () {
            return !!this.$slots.name;
        },
        hasStatus: function () {
            return !!this.$slots.name;
        },

        hasDouble: function () {
            return !!this.$slots.double;
        },

        hasSingle: function () {
            return !!this.$slots.single;
        },

        hasProfit: function () {
            return !!this.$slots.profit;
        },

        hasMargin: function () {
            return !!this.$slots.margin;
        },

        hasProfitMargin: function () {
            return !!this.$slots.profitMargin;
        }
    },
}
</script>
