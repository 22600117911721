var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "list__item" }, [
    _c(
      "div",
      { staticClass: "list__item__details is-icons" },
      [_vm._t("icons")],
      2
    ),
    _vm._v(" "),
    _vm.hasDate || (!_vm.hasNights && !_vm.hasName)
      ? _c(
          "div",
          {
            staticClass: "list__item__details is-date",
            class: { "plus-nights": !_vm.hasNights, "plus-name": !_vm.hasName },
          },
          [_vm._t("date")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasStatus
      ? _c(
          "div",
          { staticClass: "list__item__details is-date" },
          [_vm._t("status")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasSmallDate
      ? _c(
          "div",
          { staticClass: "list__item__details is-small-date" },
          [_vm._t("small-date")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasNights
      ? _c(
          "div",
          { staticClass: "list__item__details is-nights" },
          [_vm._t("nights")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasName
      ? _c(
          "div",
          { staticClass: "list__item__details is-name" },
          [_vm._t("name")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasSingle || _vm.hasDouble
      ? _c(
          "div",
          { staticClass: "list__item__details is-double" },
          [_vm._t("double")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasSingle || _vm.hasDouble
      ? _c(
          "div",
          { staticClass: "list__item__details is-single" },
          [_vm._t("single")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasProfit
      ? _c(
          "div",
          { staticClass: "list__item__details is-profit" },
          [_vm._t("profit")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasMargin
      ? _c(
          "div",
          { staticClass: "list__item__details is-margin" },
          [_vm._t("margin")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.hasProfitMargin
      ? _c(
          "div",
          { staticClass: "list__item__details is-profit-margin" },
          [_vm._t("profitMargin")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list__item__details is-buttons" },
      [_vm._t("buttons")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }