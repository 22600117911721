<template>
    <div
        v-if="!editable && !isMultiple"
        class="user"
        v-bind:class="{'is-small': small}"
        v-tooltip.top="{content: userFullName, delay: {show: 1000, hide: 0}}"
    >
        <div class="user__avatar" v-bind:style="userStyle(user)" v-if="!isMultiple"></div>
    </div>

    <div v-else-if="multipleInTable" class="users">
        <template v-for="(u, index) in user">
            <div
                class="user is-small"
                v-bind:key="u.id"
                :style="{'zIndex': user.length - index}"
            >
                <div class="user__avatar" v-bind:style="userStyle(u)"></div>
            </div>
        </template>

    </div>

    <popover
        v-else
        ref="user"
        class="userPopover"
        v-bind:delay="{show:0, hide: 0}"
        v-on:show="onOpen"
    >
        <div class="user is-editable" :class="{'is-small': small}" v-if="!isMultiple">
            <div class="user__avatar" v-bind:style="userStyle(user)"></div>
            <i class="material-icons is-pointer">edit</i>
        </div>

        <template v-else>
            <div class="users ">
                <template v-if="user.length > 0">
                    <template v-for="(u, index) in user">
                        <div
                            v-if="index < 8"
                            class="user"
                            v-bind:key="u.id ? u.id : u.username"
                            :class="{'is-small': small, 'is-editable': !index}"
                        >
                            <div class="user__avatar" v-bind:style="userStyle(u)"></div>
                            <i v-if="!index" class="material-icons is-pointer">edit</i>
                        </div>
                    </template>
                    <div class="user" v-if="user.length > 8"
                         :class="{'is-small': small}"

                    >
                        <div class="user__avatar is-text">+{{user.length - 8}}</div>
                    </div>
                </template>
                <div class="user is-editable" :class="{'is-small': small}" v-else>
                    <div class="user__avatar" v-bind:style="userStyle(null)"></div>
                    <i class="material-icons is-pointer">edit</i>
                </div>
            </div>
        </template>
        <template slot="popover">
            <multiselect
                v-model="userSelect"
                track-by="id"
                v-bind:custom-label="userLabel"
                v-bind:options="users"

                v-bind:loading="isLoading"

                v-on:search-change = "searchUsers"
                v-bind:internal-search = "false"
                :allow-empty="false"
                :multiple="isMultiple"

                v-on:input="onInput"
            >
                <span slot="caret" />
            </multiselect>

            <div class="buttons level m-t" v-if="isMultiple && quick">
                <button class="button" v-on:click.prevent.stop="selectAllUsers">Alle</button>
                <button class="button is-success" v-on:click.prevent.stop="selectCurrentUser">Nur ich</button>
            </div>
        </template>
    </popover>
</template>



<script>
import isEmpty         from 'lodash/isEmpty'
import Popover         from '@components/Popover';
import { Multiselect } from '@components/form';
import { store }       from '@utilities/store';
import { capitalize, currentUser }  from '@utilities/functions';


export default {
    store,


    props: {
        user: {
            required: true,
        },

        small: {
            type: Boolean,
            default: false,
            required: false,
        },

        editable: {
            type: Boolean,
            required: false,
            default: false,
        },

        quick: {
            type: Boolean,
            default: false,
        },

        multipleInTable: {
            type: Boolean,
            default: false,
            required: false,
        },
    },


    computed: {

        isMultiple: function () {
            return Array.isArray(this.user);
        },

        isUser: function () {
            if (!this.isMultiple) {
                return !isEmpty(this.user)
            }
            return false;
        },

        userSelect:  {
            get() {
                if (!this.isMultiple) {
                    if (this.isUser) {
                        const user = this.users
                        .find(user => (this.user.id && user.id === this.user.id) || this.user.username === this.username);
                        return !!user ? user : this.user;
                    }
                } else {
                    if(this.user[0] && this.user[0].id) {
                        return this.users.filter(user => this.user.map(u => u.id).includes(user.id))
                    } else {
                        return this.users.filter(user => this.user.map(u => u.username).includes(user.username))
                    }
                }
            },

            set(user) {
                this.$emit('input', JSON.parse(JSON.stringify(user)));
            }
        },

        userFullName: function () {
            if(this.userIsString(this.user)) {
                return this.user;
            }
            return this.user ? this.userLabel(this.user) : '';
        },

        isLoading: function () {
            return this.$store.state.users.isLoading;
        },

        users: function () {
            const users = this.$store.state.users.items[this.userSearch];
            return Array.isArray(users) ? users : [];
        },
    },


    data: function () {
        return {
            userSearch: ''
        };
    },


    methods: {
        userIsString: function (user) {
            return typeof user === 'string'
        },

        searchUsers: function (string) {
            this.userSearch = string;
            this.$store.dispatch('searchUsers', string);
        },

        userLabel: function (user) {
            const label = `${capitalize(user.firstName)} ${capitalize(user.lastName)}`;

            if (label.trim().length === 0) {
                return user.username;
            } else {
                return label;
            }
        },

        userAvatarSrc: function (user) {
            let username = null;
            if(user) {
                username = this.userIsString(user) ? user : user.username;
            }
            return username ?  '/clientSpecific/img_user/' + username + '.png' : '/clientSpecific/img_user/default.png';
        },

        userStyle: function (user) {
            return {
                'background-image' : `url(${this.userAvatarSrc(user)})`
            }
        },

        onInput: function () {
            if (!this.isMultiple) {
                this.$refs.user.hide()
            }
        },

        onOpen: function () {
            this.$store.dispatch('searchUsers', '');
        },

        selectCurrentUser: function () {
            this.userSelect = [currentUser()]
        },

        selectAllUsers: function () {
            this.userSelect = this.$store.state.users.items['']
        }
    },


    components: {
        Multiselect,
        Popover
    }
}
</script>
